const firebaseConfig = {
  apiKey: 'AIzaSyAWhTz8efJfTfAgV5YRncyvAG5NQ4_SPAk',
  authDomain: 'conwords-v2.firebaseapp.com',
  projectId: 'conwords-v2',
  storageBucket: 'conwords-v2.appspot.com',
  messagingSenderId: '408020805802',
  appId: '1:408020805802:web:df0c6238f1630285c248d3',
  measurementId: 'G-58EW58Y62E',
};

export default firebaseConfig;
