import store from './store';
import firebaseErrors from './firebaseErrors.js';
import { getFirestore, collection, query, where, getDocs, documentId } from 'firebase/firestore';
import { Howl } from 'howler';

window.actions = {};

let data = {};
let sndClick = [
  new Howl({
    src: ['/snd.1/click.wav'],
  }),
  new Howl({
    src: ['/snd.2/click.wav'],
  }),
];
let sndFade = [
  new Howl({
    src: ['/snd.1/fade.wav'],
  }),
  new Howl({
    src: ['/snd.2/fade.wav'],
  }),
];
let sndFail = [
  new Howl({
    src: ['/snd.1/fail.wav'],
  }),
  new Howl({
    src: ['/snd.2/fail.wav'],
  }),
];
let sndComplete = [
  new Howl({
    src: ['/snd.1/complete.wav'],
  }),
  new Howl({
    src: ['/snd.2/complete.wav'],
  }),
];
let sndWord = [
  new Howl({
    src: ['/snd.1/word.wav'],
  }),
  new Howl({
    src: ['/snd.2/word.wav'],
  }),
];

let lastTime = 0;
let _db = undefined;
const userMap = {};
const cruciMap = {};
let unsuscribe = null;
const firstArray = [];
let lastPlayFailTime = 0;
let lastLoadingTime = 0;
let first = true;
let lastSize = '';

const mixin = {
  created() {
    const check = () => {
      const size = window.innerWidth + 'x' + window.innerHeight;
      if (lastSize === size) return;
      lastSize = size;
      let zoom = 1;
      // if (width < 270) {
      //   zoom = 0.84;
      // } else if (width < 320) {
      //   zoom = 0.96;
      // } else if (width < 640) {
      //   zoom = 1.11;
      // } else if (width < 1920) {
      //   zoom = 1.25;
      // } else {
      //   zoom = 1.5 * (Math.pow(width, 0.95) / 1920);
      // }
      store.commit('setZoom', zoom);
      document.body.style.setProperty('zoom', zoom);
    };
    //window.onresize = check;
    check();
  },
  methods: {
    getData: function (name) {
      //console.log('getData', name, data[name]);
      return data[name];
    },
    setData: function (name, value) {
      //console.log('setData', name, value);
      if (value === undefined) {
        delete data[name];
      } else {
        data[name] = value;
      }
    },
    first(id) {
      if (firstArray.includes(id)) {
        return false;
      } else {
        firstArray.push(id);
        return true;
      }
    },

    listenSnapshot(_unsuscribe) {
      if (unsuscribe) {
        unsuscribe();
        unsuscribe = null;
      }
      unsuscribe = _unsuscribe;
    },
    delay: async (delay) => {
      return new Promise((resolve) => {
        setTimeout(resolve, delay);
      });
    },

    delayTime: () => {
      const time = Date.now();
      const delay = time - lastTime;
      lastTime = time;
      return delay;
    },
    async playClick(snd = 1) {
      if (first) {
        await this.delay(10);
        first = false;
      }
      if (snd === 1 || snd === 2) sndClick[snd - 1].play();
    },
    playFade(snd = 1) {
      if (snd === 1 || snd === 2) sndFade[snd - 1].play();
    },
    playComplete(snd = 1) {
      if (snd === 1 || snd === 2) sndComplete[snd - 1].play();
    },
    playWord(snd = 1) {
      if (snd === 1 || snd === 2) sndWord[snd - 1].play();
    },
    playFail(snd = 1) {
      const now = Date.now();
      if (now - lastPlayFailTime > 2000) {
        if (snd === 1 || snd === 2) sndFail[snd - 1].play();
      }
      lastPlayFailTime = now;
    },
    getLocal(key) {
      return localStorage.getItem(this.$store.getters.uid + key);
    },
    setLocal(key, value) {
      localStorage.setItem(this.$store.getters.uid + key, value);
    },
    removeLocal(key) {
      localStorage.removeItem(this.$store.getters.uid + key);
    },
    async getUsers(uids) {
      const uidsLoaded = Object.keys(userMap);
      let uidsNotFound = [...new Set(uids.filter((uid) => uid !== undefined && !uidsLoaded.includes(uid)))];
      if (uidsNotFound.length > 0) {
        const db = getFirestore();
        const q = await query(collection(db, 'users'), where(documentId(), 'in', uidsNotFound));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          userMap[doc.id] = { username: doc.data().username, avatar: doc.data().avatar };
        });
      }
      return userMap;
    },
    cruciData(id, data) {
      if (id) {
        //console.log('crucidata ' + id);
        cruciMap[id] = data;
      }
      return cruciMap;
    },
    confirm(msg, action) {
      const id = Date.now();
      actions[id] = action;
      msg = {
        message: msg + '<br><br><button style="float:right;margin-left:10px">Cancel</button><button style="float:right" class="btn btn-primary" onclick="actions[' + id + ']();">OK</button>',
        onDismiss: () => {
          delete actions[id];
        },
      };
      this.notification(msg);
    },
    prompt(msg, action) {
      const id = Date.now();
      actions[id] = action;
      msg = {
        message: msg + '<br><input onkeydown=\'if (event.code==="Enter"){const input=document.getElementById("input' + id + '");actions[' + id + '](input.value);input.parentNode.click()}\' onclick="this.focus();event.stopPropagation();event.preventDefault();" id="input' + id + '" style="color:black;width:100%"><button style="float:right;margin-left:10px">Cancel</button><button style="float:right" class="btn btn-primary" onclick="actions[' + id + "](document.getElementById('input" + id + '\').value);">OK</button>',
        onDismiss: () => {
          delete actions[id];
        },
      };
      this.notification(msg);
      setTimeout(() => {
        const input = document.getElementById('input' + id);
        input.focus();
      }, 100);
    },

    formatDate(date) {
      const options = {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      };
      let txt = new Date(date * 1000).toLocaleDateString('es', options);
      return txt.toUpperCase();
    },
    formatTime(date) {
      const options = {
        hour: '2-digit',
        minute: '2-digit',
      };
      return new Date(date * 1000).toLocaleDateString('es', options);
    },
    db() {
      return _db;
    },
    correoValido(correo) {
      const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[A-Z][A-Z]+/i;
      const match = correo.match(emailRegex);

      const result = match !== null && match[0] === correo;
      return result;
    },
    passwordValido(password) {
      const result = password.length >= 6;
      return result;
    },
    initDB(uid) {
      if (window.indexedDB) {
        let openRequest = window.indexedDB.open(uid, 4);

        openRequest.onerror = function () {
          this.notification(openRequest.error);
        };

        openRequest.onupgradeneeded = function () {
          let db = openRequest.result;
          if (!db.objectStoreNames.contains('diccionarios')) {
            db.createObjectStore('diccionarios', { keyPath: 'nombre' }); // crearlo
          }
          if (!db.objectStoreNames.contains('compilacion')) {
            db.createObjectStore('compilacion', { keyPath: 'nombre' }); // crearlo
          }
        };

        openRequest.onsuccess = function () {
          _db = openRequest.result;
        };
      }
    },
    setLoading(l) {
      if (l === true) {
        lastLoadingTime = Date.now();
      } else {
        document.getElementById('update-check').style.display = 'none';
      }
      const wait = l === true ? 0 : Math.max(1000 - Date.now() + lastLoadingTime, 0);
      setTimeout(() => {
        store.commit('setLoading', l);
      }, wait);
    },
    notification: function (message, title = '', type = 'info', onDismiss = () => {}) {
      if (message === undefined) {
        this.$toast.clear();
        return;
      }
      let duration = 0;
      if (typeof message === 'object') {
        const error = message;
        onDismiss = message.onDismiss ? message.onDismiss : () => {};
        message = firebaseErrors[error.code];
        if (message === undefined) {
          message = (error.code ? error.code + ': ' : '') + error.message;
        }
        type = 'error';
        //console.trace(error);
      } else {
        duration = Math.max(3000, message.length * 200);
      }
      message = title.trim().length > 0 ? '<h3>' + title + '</h3>' + message : message;
      this.$toast.open({
        message,
        type,
        duration,
        onDismiss,
        position: 'bottom-right',
      });
    },
    error: function (message) {
      this.notification({ code: 'ERROR', message });
    },
  },
};

export default mixin;
