<template>
  <div class="main-container">
    <Loading />
    <nav id="menu">
      <button v-if="$router.currentRoute.path === '/'" style="position: absolute; padding: 0; top: 6px; left: 1px; letter-spacing: 0px; text-align: center; width: 72px; color: yellow">
        <select @click="play" @change="change" style="background: none; padding: 0; outline: none; font-size: 10px; text-align: center; font-weight: bolder">
          <option value="FACIL" :selected="modo === 'FACIL'" style="background: black; border: none; padding: 4px">FACIL</option>
          <option value="MASTER" :selected="modo === 'MASTER'" style="background: black; border: none; padding: 4px">MASTER</option>
        </select>
      </button>
      <ul>
        <a @click="click" :class="{ 'router-link-exact-active': menu }" tabindex="-1">
          <li><ThemifyIcon icon="menu" style="font-size: 21px; top: -2px; position: relative" /></li>
        </a>
        <router-link tabindex="-1" v-if="menu" :to="auth ? '/admin/config' : '/admin/login'" :class="{ 'router-link-exact-active': $router.currentRoute.path === '/admin/account' }">
          <li><ThemifyIcon icon="user" style="font-size: 21px; top: -2px; position: relative" /></li>
        </router-link>
        <router-link tabindex="-1" v-if="menu" to="/admin/about" :class="{ 'router-link-exact-active': $router.currentRoute.path === '/admin/about' }">
          <li><ThemifyIcon icon="book" style="font-size: 21px; top: -2px; position: relative" /></li>
        </router-link>

        <router-link tabindex="-1" v-if="menu && auth" to="/admin/words" :class="{ 'router-link-exact-active': ['/admin/words', '/admin/words'].includes($router.currentRoute.path) }">
          <li><ThemifyIcon icon="pencil-alt" style="font-size: 21px; top: -2px; position: relative"></ThemifyIcon></li>
        </router-link>
        <router-link tabindex="-1" v-if="menu && auth" to="/admin/build" :class="{ 'router-link-exact-active': ['/admin/build', '/admin/build'].includes($router.currentRoute.path) }">
          <li><ThemifyIcon icon="layout-grid4-alt" style="font-size: 21px; top: -2px; position: relative"></ThemifyIcon></li>
        </router-link>
        <router-link tabindex="-1" v-if="menu" to="/" :class="{ 'router-link-exact-active': $router.currentRoute.path === '/' }">
          <li><ThemifyIcon icon="home" style="font-size: 21px; top: -2px; position: relative"></ThemifyIcon></li>
        </router-link>
      </ul>
      <div style="clear: both"></div>
    </nav>
    <div>
      <br /><br />
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import Loading from './components/Loading.vue';
import { mapGetters } from 'vuex';
import ThemifyIcon from 'vue-themify-icons';
export default {
  name: 'App',
  components: {
    ThemifyIcon,
    Loading,
  },
  data() {
    return {
      version: '',
      menu: false,
      audio: 1,
      modo: 'FACIL',
    };
  },
  computed: {
    ...mapGetters(['uid', 'auth']),
  },

  methods: {
    play: function () {
      let s = this.getLocal('audio');
      this.audio = s ? parseInt(s) : 2;
      this.playClick(this.audio);
    },
    change: function (evt) {
      const val = evt.target.value;
      this.modo = val;

      let s = this.getLocal('audio');
      this.audio = s ? parseInt(s) : 2;
      //console.log(this.uid + ' -> ' + val + ' ' + this.audio);
      this.playFade(this.audio);
      if (val === 'FACIL') {
        this.notification('Entraste al <strong>MODO FACIL</strong>, eso significa que te mostraremos las letras de la palabra');
      } else {
        this.notification('Entraste al <strong>MODO MASTER</strong>, eso significa que NO te mostraremos las letras de la palabra');
      }
      this.setLocal('modo', val);
      this.playClick(this.audio);
    },
    reload: function () {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
      location.reload();
    },

    click() {
      this.menu = !this.menu;
      this.setLocal('menu', this.menu);
      const s = this.getLocal('audio');
      const audio = s ? parseInt(s) : 2;
      this.playClick(audio);
    },
    setModo(val) {
      const root = document.querySelector(':root');
      if (val === 'MASTER') {
        root.style.setProperty('--color-texto', 'rgb(98, 124, 101)');
        root.style.setProperty('--color-fondo', 'black');
        root.style.setProperty('--color-disabled', 'gray');
        root.style.setProperty('--color-gradient', 'rgb(41, 83, 42, 0.5)');
        root.style.setProperty('--color-select', 'rgb(217,207, 44,.97)');
        root.style.setProperty('--color-negate-select', 'white');
      } else {
        root.style.setProperty('--color-texto', 'rgb(98, 124, 101)');
        root.style.setProperty('--color-fondo', 'black');
        root.style.setProperty('--color-disabled', 'gray');
        root.style.setProperty('--color-gradient', 'rgb(92, 113, 161, 0.75)');
        root.style.setProperty('--color-select', 'rgb(74, 187, 147)');
        root.style.setProperty('--color-negate-select', 'white');
      }
    },
  },

  async mounted() {
    let s = this.getLocal('audio');
    this.audio = s ? parseInt(s) : 2;
    s = this.getLocal('menu');
    this.menu = 'true' === s;
    s = this.getLocal('fondo');
    s = s ? parseInt(s) : 2;
    document.body.style.background = `url(/img/bg${s}.png)`;

    s = this.getLocal('modo');
    this.modo = s ? s : 'FACIL';
    this.setModo(this.modo);
    if (window.newVersion) {
      this.notification('Bienvenido a la versión ' + window.newVersion);
    }
    if (window.newMessage) {
      this.notification(window.newMessage);
    }
  },
  watch: {
    modo: function (val) {
      this.setModo(val);
    },
    auth: function () {
      if (this.auth && this.$route.meta.authGoto !== undefined) {
        this.$router.replace(this.$route.meta.authGoto);
      } else if (!this.auth && this.$route.meta.noAuthGoto !== undefined) {
        this.$router.replace(this.$route.meta.noAuthGoto);
      }
      let s = this.getLocal('menu');
      this.menu = 'true' === s;
    },
  },
};
</script>
<style scoped>
@import './assets/reset.css';
@import './assets/style.css';
</style>
