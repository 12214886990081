import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    /**Es true cuando termina la inicializacion de Firebase */
    ready: false,
    /**Propiedades de la autenticacion*/
    credentials: null,
    loading: true,

    //////////////////////////////////////////////////////////
    /////// CONSTANTES DE CONFIGURACION (SOLO LECTURA) ///////
    //////////////////////////////////////////////////////////

    config: Object.freeze({
      validatePassword: (pwd) => {
        if (pwd.length < 6) return false;
        //if (pwd.match(/[a-z]/gi) === null) return false;
        //if (pwd.match(/[0-9]/gi) === null) return false;
        return true;
      },
      auth: {
        email: true,
        google: true,
        facebook: false,
        twitter: false,
        github: false,
        apple: false,
        microsoft: false,
      },
    }),
    width: window.innerWidth,
    zoom: 1,
  },
  getters: {
    /**Indica si el usuario esta autenticado */
    auth: (state) => state.credentials !== null && state.credentials.emailVerified,
    /**Indica el uid del usuario*/
    uid: (state) => state.credentials?.uid,
    getZoom: (state) => state.zoom,
  },
  mutations: {
    setZoom(state, zoom) {
      state.zoom = zoom;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setCredentials(state, credentials) {
      if (!state.ready) {
        state.ready = true;
      }
      if (JSON.stringify(state.credentials) !== JSON.stringify(credentials)) {
        state.credentials = credentials;
      }
    },
  },
  actions: {},
  modules: {},
});
