import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index.js';
import mixin from '../mixin.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'List',
    component: () => import('../views/List.vue'),
  },
  {
    path: '/-:cruciId',
    name: 'Play',
    component: () => import('../views/Play.vue'),
  },

  {
    path: '/:username',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/admin/about',
    name: 'About',
    component: () => import('../views/About.vue'),
  },

  {
    path: '/admin/build',
    name: 'Build',
    meta: { noAuthGoto: '/admin/login' },
    component: () => import('../views/Build.vue'),
  },
  {
    path: '/admin/words',
    name: 'Words',
    meta: { noAuthGoto: '/admin/login' },
    component: () => import('../views/Words.vue'),
  },
  {
    path: '/admin/account',
    name: 'Account',
    meta: { noAuthGoto: '/admin/login' },
    component: () => import('../views/Account.vue'),
    children: [
      {
        path: '/admin/login',
        name: 'Login',
        meta: { authGoto: '/' },
        component: () => import('../views/Login.vue'),
      },

      {
        path: '/admin/signin',
        name: 'SignIn',
        meta: { authGoto: '/' },
        component: () => import('../views/SignIn.vue'),
      },
      {
        path: '/admin/reset',
        name: 'Reset',
        meta: { authGoto: '/' },
        component: () => import('../views/Reset.vue'),
      },
      {
        path: '/admin/config',
        name: 'config',
        meta: { noAuthGoto: '/admin/login' },
        component: () => import('../views/Config.vue'),
      },
    ],
  },

  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

let lastTime = 0;
router.beforeEach((to, from, next) => {
  if (from.name !== null && !(to.fullPath.startsWith(from.fullPath) || from.fullPath.startsWith(to.fullPath))) {
    mixin.methods.setLoading(true);
  }
  if (to.name !== 'Play') {
    document.body.style.overflowY = 'auto';
  }
  const now = Date.now();
  const delta = now - lastTime;
  lastTime = now;
  // console.log('ROUTER DELTA:', delta, from.fullPath, '->', to.fullPath);
  if (to.fullPath === '/' && (from.hash === '#CFG' || from.hash === '#TIP')) {
    next({ path: from.fullPath.replace(/#.*$/, '') });
  } else {
    if (from.fullPath !== to.fullPath && delta > 250) {
      const s = localStorage.getItem(store.getters.uid + 'audio');
      const audio = s ? parseInt(s) : 2;
      mixin.methods.playClick(audio);
    }
  }

  const isAuth = store.getters.auth;

  if (to.meta.noAuthGoto && !isAuth) {
    next({ path: to.meta.noAuthGoto });
  } else if (to.meta.authGoto && isAuth) {
    next({ path: to.meta.authGoto });
  } else {
    next();
  }
});

export default router;
