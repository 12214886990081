import Vue from 'vue';
import pjson from '../package.json';
import router from './router';
import store from './store';
import App from './App.vue';
import './registerServiceWorker';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import firebaseConfig from './firebaseConfig.js';
import mixin from './mixin.js';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { collection, getDoc, doc, getFirestore, runTransaction } from 'firebase/firestore';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Device } from '@capacitor/device';
import { App as CapacitorApp } from '@capacitor/app';

window.newVersion = null;
window.newMessage = null;

CapacitorApp.addListener('backButton', ({ canGoBack }) => {
  if (!canGoBack) {
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});

(async function () {
  const version = pjson.version;
  document.title = pjson.name.replace('-', ' ').toUpperCase() + ' ' + version + '(BETA)';
  await initializeApp(firebaseConfig);
  const db = getFirestore();

  const infoDevice = await Device.getInfo();
  if (infoDevice.platform !== 'web') {
    //StatusBar.setOverlaysWebView({ overlay: true });
    //Capacitor.Plugins.StatusBar.setStyle({ style: Style.Light });
    await StatusBar.setStyle({ style: Style.Light });
    await StatusBar.hide();
  } else {
    document.getElementById('update-check').style.display = 'block';
    const docRef = doc(db, 'app_info', 'info');
    const info = await getDoc(docRef);
    const data = info.data();
    if (data.version !== version) {
      caches.keys().then((names) => {
        for (let name of names) {
          console.log('borrando cache', name);
          caches.delete(name);
        }
      });
      window.location.reload();
    } else {
      const prevVersion = window.localStorage.getItem('version');
      if (prevVersion !== pjson.version) {
        window.newVersion = pjson.version;
        window.localStorage.setItem('version', pjson.version);
      }
      const prevMessage = window.localStorage.getItem('message');
      if (prevMessage !== data.msg) {
        window.newMessage = data.msg;
        window.localStorage.setItem('message', data.msg);
      }
    }
  }
  let isMobile = (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i)) !== null;
  if (isMobile) {
    screen.orientation
      .lock('portrait')
      .then(() => {
        console.log(`Locked to portrait\n`);
      })
      .catch((error) => {
        console.log(`${error}\n`);
      });
  }

  //console.clear();
  Vue.use(VueToast);
  Vue.config.productionTip = false;
  if (process.env.NODE_ENV !== 'development') {
    document.addEventListener('contextmenu', (event) => event.preventDefault());
  }

  Vue.mixin(mixin);
  let vue = undefined;

  onAuthStateChanged(getAuth(), async (credentials) => {
    try {
      await runTransaction(db, async (transaction) => {
        const _credentials = credentials
          ? {
              nombre: credentials.displayName || '',
              uid: credentials.uid,
              email: credentials.email,
              avatar: credentials.photoURL,
              emailVerified: credentials.emailVerified,
            }
          : null;

        if (_credentials && _credentials.emailVerified) {
          mixin.methods.initDB(_credentials.uid);
          let data;
          const docSnap = await transaction.get(doc(db, 'users', credentials.uid));
          //Si no existe el uid en usernames
          if (!docSnap.exists()) {
            let username,
              docExists = true;
            while (docExists) {
              username = 'user' + Math.trunc(Math.random() * 10000000); //Mejorar esto
              docExists = await (await transaction.get(doc(db, 'usernames', username))).exists();
            }

            data = { ..._credentials, presentacion: '', username };
            await transaction.set(doc(collection(db, 'usernames'), username), { uid: credentials.uid });
            await transaction.set(doc(collection(db, 'users'), credentials.uid), data);
          } else {
            data = docSnap.data();
          }
          store.commit('setCredentials', { ..._credentials, ...data, lastLoginAt: new Date(parseInt(credentials.metadata.lastLoginAt)) });
        } else {
          store.commit('setCredentials', null);
        }
        if (vue === undefined) {
          vue = new Vue({
            router,
            store,
            render: (h) => h(App),
          }).$mount('#app');
        }
      });
    } catch (error) {
      console.trace(error);
      alert(error.message);
    }
  });
})();
